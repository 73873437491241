import React from "react"
import { VideoWrapped } from "../../components/reactPlayerTest"
import "../../components/animations/scroll.scss"
import ImageMeta from "../../components/ImageMeta"

function Sizzle(props) {
  return (
    <div className="home-sizzle-wrapper">
      <ImageMeta
        className="home-sizzle-image"
        publicId="AOS/DEV/about-practice-photo-desktop"
        cloudname="nuvolum"
      />
      {/* <VideoWrapped playing={props.playing} id="test-player-1" /> */}
      {props.children}
    </div>
  )
}

export default Sizzle
