import React from "react"
import Button from "@bit/azheng.joshua-tree.button"
import ImageMeta from "../ImageMeta"

const WestinHotel = ({ heading, blurb, image, button }) => {
  return (
    <div className="westin">
      <ImageMeta cloudname="nuvolum" publicId={image} width="auto" />
      <div className="westin__body">
        <div className="westin__content">
          <h5>{heading}</h5>
          <p>{blurb}</p>
        </div>
        {/* <Button
          buttonText="Reserve a Room"
          href="https://www.google.com/url?q=https://www.marriott.com/event-reservations/reservation-link.mi?id%3D1646947567437%26key%3DCORP%26app%3Dresvlink&sa=D&source=docs&ust=1648496336403855&usg=AOvVaw1xzh3xMv3bc_yAVsMp2WYj"
          contained
          external
        /> */}
      </div>
    </div>
  )
}

export default WestinHotel
