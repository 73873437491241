import React from "react"
import ImageMeta from "../ImageMeta"
import DesignLogo from "../../images/dsd-large-white.svg"

const DigitalSmile = () => {
  return (
    <div className="dsd">
      <div className="dsd__text">
        <h3>Certified Digital Smile Design Clinic</h3>
        <img src={DesignLogo} alt="Digital Smile Design logo" />
      </div>
      <ImageMeta
        className="dsd__bg-bar"
        publicId="AOS/DEV/dsd-bar"
        cloudName="nuvolum"
        width="auto"
        responsive
        responsiveUseBreakpoints="true"
      />
    </div>
  )
}

export default DigitalSmile
