import React, { useState } from "react"
import { Link } from "gatsby"

import "./ProgramToast.scss"

//Props
// href = page url
// endDate = program application end date, format = month day year time timezone (September 23 2021 23:59:00 CDT)
// program = program name ex. "smile again"

const ProgramToast = ({ href, endDate, program }) => {
  program = program.toLowerCase().trim()

  const [close, setClose] = useState(false)

  const today = new Date()
  const end = new Date(endDate)

  const dateWords = endDate.split(" ")
  const displayDate = `${dateWords[0]} ${dateWords[1]}`

  if (close || !program || today > end) return <></>

  // program prop needs to match the name of a key
  const programs = {
    "smile again": (
      <p>
        Apply to the <Link to={href}>Smile Again</Link> program by {displayDate}{" "}
        for a chance to receive a new smile.
      </p>
    ),
    "second chance": (
      <p>
        Apply to the <Link to={href}>Second Chance</Link> program by{" "}
        {displayDate} for a chance to receive a new smile.
      </p>
    ),
    "smiles for soldiers": (
      <p>
        We're providing two active duty soldiers or military veterans with a new
        smile. <Link to={href}>Apply by {displayDate}!</Link>
      </p>
    ),
    wisdom: (
      <p>
        We're providing FREE wisdom teeth removal surgery to a local student.{" "}
        <Link to={href}>Apply before {displayDate}!</Link>
      </p>
    )
  }

  return (
    <div className="program-toast">
      <span className="close-toast" onClick={() => setClose(true)}>
        ✖
      </span>
      {programs[program]}
    </div>
  )
}

export default ProgramToast
